/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

const getSafeVersion = () => {
    try {
        return SERVICE_VERSION;
    } catch (e) {
        return 'Test';
    }
};

const getSafeEnvironment = () => {
    try {
        return SERVICE_ENVIRONMENT;
    } catch (e) {
        return 'Test';
    }
};

const release = getSafeVersion();
const environment = getSafeEnvironment();
const isProduction = environment === 'production';

// should have been called before using it here
// ideally before even rendering your react app
Sentry.init({
    dsn: isProduction ? config.sentryToken : null,
    environment,
    release,
});

export const reportErrorToSentry = (...args) => {
    Sentry.captureException(...args);
    console.log(...args);
};

const MODULE_NAME = ' position-sharing-web';

export const sendError = (exception) => {
    reportErrorToSentry(exception, {
        tags: {
            module: MODULE_NAME,
        },
    });
};
