export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        ASSET_ADMINISTRATION: string | undefined;
        POSITION_SHARING: string | undefined;
        PRODUCT_ACTIVATIONS_API: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    pollInterval: number | undefined;
    sentryToken: string | undefined;
}

const asBool = (value: string | undefined): boolean => value === 'true';

const getNumberEnvValue = (envValue: string | undefined): number | undefined => {
    if (envValue === undefined) {
        return undefined;
    }
    return Number(envValue);
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        ASSET_ADMINISTRATION: import.meta.env.VITE_ASSET_ADMINISTRATION,
        POSITION_SHARING: import.meta.env.VITE_POSITION_SHARING,
        PRODUCT_ACTIVATIONS_API: import.meta.env.VITE_PRODUCT_ACTIVATIONS_API,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: 'ae80768a-fc9f-482c-bec0-3384bec2112f',
        oauthScope: ['openid', 'profile', 'email', 'position-sharing.write'],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    pollInterval: getNumberEnvValue(import.meta.env.VITE_POSITION_POLL_INTERVAL),
    sentryToken: undefined,
};
