import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { Asset } from '../../model/assets.types';
import { State } from '../../model/general.types';
import { AssetBookings } from '../../model/productInformation.types';

const name = 'assetData';

interface AssetDataState {
    assets: Asset[];
    assetBookings?: AssetBookings;
}

const initialState: AssetDataState = {
    assets: [],
    assetBookings: undefined,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setAssets: (state: AssetDataState, action: PayloadAction<Asset[]>): void => {
            state.assets = action.payload;
        },
        setAssetBookings: (state: AssetDataState, action: PayloadAction<AssetBookings>): void => {
            state.assetBookings = action.payload;
        },
    },
});

export const assetDataActions = actions;
export const assetDataReducer = reducer;

const getBase = (state: State): AssetDataState | undefined => get(state, name);
export const getAssets = (state: State): Asset[] => get(getBase(state), 'assets', []);
export const getAssetBookings = (state: State): AssetBookings | undefined =>
    get(getBase(state), 'assetBookings', undefined);
