import { configureStore as configureStoreReduxToolkit } from '@reduxjs/toolkit';

import { rootReducer } from '../../reducers';

const reducer = rootReducer();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const configureStore = () =>
    configureStoreReduxToolkit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
    });

export const store = configureStore();
