export enum ApiCalls {
    ASSETS = 'Assets',
    ASSET_BOOKINGS = 'AssetBookings',
    TRACKINGS_FETCH = 'TrackingsFetch',
    TRACKING_PUT_CREATE = 'TrackingsPutCreate',
    TRACKING_PUT_UPDATE = 'TrackingsPutUpdate',
}

export enum ErrorCauses {
    ASSETS_FETCH = 'assetsFetch',
    ASSET_BOOKINGS_FETCH = 'assetBookingsFetch',
    TRACKING_PUT_CREATE = 'trackingPutCreate',
    TRACKING_PUT_UPDATE = 'trackingPutUpdate',
    TRACKING_DELETE = 'trackingDelete',
    TRACKINGS_FETCH = 'trackingsFetch',
}

export interface ApiLoadingState {
    loading: { [key: string]: boolean };
    error: { [key: string]: boolean };
}
