import { apiCallReducer } from '../domain/service/redux/api.redux';
import appReducer from './app/redux/reducers';
import { combineReducers, Reducer } from 'redux';
import { assetDataReducer } from '../domain/service/redux/assetData.redux';
import { trackingDataReducer } from '../domain/service/redux/trackingData.redux';
import { trackingAdministrationReducer } from '../domain/service/redux/trackingAdminstration.redux';
import configReducer from './configuration/setup/configReducer';
import langReducer from './configuration/lang/reducer';
import { tokenHandlingReducer } from './configuration/tokenHandling/reducer';
import { loginReducer } from './configuration/login/reducer';

export const rootReducer = (): Reducer =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenHandlingReducer,
        assetData: assetDataReducer,
        api: apiCallReducer,
        trackingData: trackingDataReducer,
        trackingAdministration: trackingAdministrationReducer,
    });
