import './polyfills';

import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { main, handleLoginRedirect, store as storePrivateApp } from './application/configuration';
import { NoMatch } from './NoMatch';
import { ErrorBoundary } from './ErrorBoundary';
import { config } from './application/config';
import { LoadingIndicator } from './common/displaycomponents/LoadingIndicator';
import { createRoot } from 'react-dom/client';

const PrivateAppContainer = lazy(
    () => import(/* webpackChunkName: "PrivateApp" */ './application/app/PrivateApp.container')
);

const renderApplication = (): void => {
    const container = document.getElementById('root');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(container!);

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    root.render(
        <ErrorBoundary>
            <Provider store={storePrivateApp}>
                <BrowserRouter>
                    <Suspense fallback={LoadingIndicator}>
                        <Routes>
                            <Route path={'/error'} element={<NoMatch />} />
                            <Route path={'/'} element={<PrivateAppContainer />} />
                            <Route element={<NoMatch />} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
