import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { State } from '../../model/general.types';

interface TrackingAdministrationState {
    selectedItemId?: string;
}

const initialState: TrackingAdministrationState = {
    selectedItemId: undefined,
};

const name = 'trackingAdministration';

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setSelectedItem: (state: TrackingAdministrationState, action: PayloadAction<string | undefined>): void => {
            state.selectedItemId = action.payload;
        },
    },
});

export const trackingAdministrationActions = actions;
export const trackingAdministrationReducer = reducer;

const getBase = (state: State): TrackingAdministrationState | undefined => get(state, name);
export const getSelectedItemId = (state: State): string | undefined => get(getBase(state), 'selectedItemId');
