import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { State } from '../../model/general.types';

const name = 'trackingData';

export interface Tracking {
    assetId: string;
    id: string;
    name: string;
    comment?: string;
    start: Date;
    end: Date;
    linkValidStart: Date;
    linkValidEnd: Date;
    linkValidationSecret: string;
}

interface AppState {
    trackings: Tracking[];
}

const initialState: AppState = {
    trackings: [],
};

const upsertTracking = (existingTrackings: Tracking[], newTracking: Tracking): Tracking[] => {
    if (existingTrackings.map((t: Tracking) => t.id).includes(newTracking.id)) {
        return existingTrackings.map((old: Tracking) => (old.id === newTracking.id ? newTracking : old));
    }
    return [...existingTrackings, newTracking];
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setTrackings: (state: AppState, action: PayloadAction<Tracking[]>): void => {
            state.trackings = action.payload;
        },
        updateTracking: (state: AppState, action: PayloadAction<Tracking>): void => {
            state.trackings = upsertTracking(state.trackings, action.payload);
        },
        deleteTracking: (state: AppState, action: PayloadAction<string>): void => {
            state.trackings = state.trackings.filter((tracking) => tracking.id !== action.payload);
        },
    },
});

export const trackingDataActions = actions;
export const trackingDataReducer = reducer;

const getBase = (state: State): AppState | undefined => get(state, name);
export const getTrackings = (state: State): Tracking[] => get(getBase(state), 'trackings', []);
export const getTracking = (state: State, id: string): Tracking | undefined =>
    get(getBase(state), 'trackings', []).find((tracking) => tracking.id === id);
